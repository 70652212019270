<template>
  <div>
    <div v-if="!loading">
      <!-- Adjusted v-card with flat and full-width/height styles -->
      <v-card flat style="background-color: var(--v-background-base) !important">
        <!-- Reduced height of the toolbar -->
        <v-toolbar color="greyBase" dark flat dense style="height: 48px;">
          <!-- Toolbar Title with smaller font size -->
          <v-toolbar-title style="font-size: 20px;">
            <b>Stock Overview</b>
          </v-toolbar-title>
        </v-toolbar>

        <!-- Menu Moved to New Line Underneath Title with updated button styles -->
        <v-row no-gutters align="start" justify="space-between" style="height: 28px;">

          <v-col cols="auto">
          <v-btn-toggle
            mandatory
            v-model="tab"
            color="blue"
          >
            <v-btn small outlined style="background-color: var(--v-greyDarker-base); color: white;"> 
              <v-icon left small :color="tab == 0 ? 'blue' : 'primaryText'">widgets</v-icon>
              <b style="text-transform: none">Dashboard</b>
            </v-btn>
            
            <v-btn small outlined style="background-color: var(--v-greyDarker-base); color: white;">
              <v-icon left small :color="tab == 1 ? 'blue' : 'primaryText'">grading</v-icon>
              <b style="text-transform: none;">Allocate Orders</b>
            </v-btn>
            
            <v-btn small outlined style="background-color: var(--v-greyDarker-base); color: white;">
              <v-icon small left :color="tab == 2 ? 'blue' : 'primaryText'">insights</v-icon>
              <b style="text-transform: none;">Inventory Overview</b>
            </v-btn>

            <v-btn small outlined style="background-color: var(--v-greyDarker-base); color: white;">
              <v-icon small left :color="tab == 3 ? 'blue' : 'primaryText'">format_list_bulleted</v-icon>
              <b style="text-transform: none;">All Inventory</b>
            </v-btn>

            <v-btn small outlined style="background-color: var(--v-greyDarker-base); color: white;">
              <v-icon small left :color="tab == 4 ? 'blue' : 'primaryText'">format_list_bulleted</v-icon>
              <b style="text-transform: none;">All Inventory(New)</b>
            </v-btn>
          </v-btn-toggle>
        </v-col>
        </v-row>

        <!-- Adjusted v-card without rounded corners -->
        <v-card class="mx-auto" :max-width="'100%'" :style="{
            'margin-top': '0px',
            'border-radius': '0px'
        }">
          <div :style="{
              height: '90vh',
              'overflow-y': 'auto'
          }" id="navbar-div1">
            <!-- Tab Components for Different Views -->
            <Dashboard v-if="tab == 0" style="height: 100%"/>
            <OrderAllocate v-else-if="tab==1" style="height: 100%"/>
            <Inventory v-else-if="tab==2" style="height: 100%"/>
            <RawInventory v-else-if="tab==3" style="height: 100%;"/>
            <RawInventoryNew v-else-if="tab==4" style="height: 100%" />
        </div>
        </v-card>
      </v-card>
    </div>

    <!-- Loading Screen -->
    <div v-else style="
      height: 90vh;
      padding-top: 0;
      margin-top: 0;
      background: transparent;
    " class="text-center">
      <valhalla-loading :height="'85vh'" />
    </div>
  </div>
</template>

<script>
import Inventory from './OverviewTabs/Inventory.vue'
// import NetworkMap from './OverviewTabs/NetworkMap.vue'
import Dashboard from './Dashboard.vue'
// import Order from '../../views/Order.vue'
import OrderAllocate from './OverviewTabs/OrderAllocate.vue'
import RawInventory from './OverviewTabs/RawInventory.vue'
import RawInventoryNew from './OverviewTabs/RawInventoryNew.vue'


export default {
  name: "StockOverview",
  components: {
    Inventory,
    // NetworkMap,
    Dashboard,
    // Order,
    OrderAllocate,
    RawInventory,
    RawInventoryNew
  },
  data: () => ({
    loading: false,
    stock: [],
    tab: null,
  }),
  created() {
    // this.getStockOverview()
  },
  methods: {
    async getStockOverview() {
      this.loading = true
      this.stock = await this.$API.getStockOverview()
      this.stock.forEach((x, i) => {
        x.key = (1 + 1) * 1000
      })
      this.loading = false
    }
  },
}
</script>
